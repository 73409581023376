import React from 'react';
import { Box, Button, Center, Image } from '@chakra-ui/react';
import { LeicaLink } from '../ALink'
import { useTranslation } from 'react-i18next';

const BottomCallToActionSection = () => {
  const {t} = useTranslation();

  return (
    <Box h={{base: "320px", md: "600px"}}
      backgroundImage="/images/new/bottom_illustration.png"
      backgroundSize="100%"
      backgroundPosition="60% 50%"
      position="relative"
    >
      <Box
        w="100%"
        position="absolute"
        bottom="50px"
      >
        <Center>
          <LeicaLink href={`https://app.leiacatering.ee/create_inquiry`}>
            <Button colorScheme="orange" size="xl">
              {t('home:heroButtonAskOffer')}
            </Button>
          </LeicaLink>
        </Center>
      </Box>
    </Box>
  )
}

export default BottomCallToActionSection;
