import React, { useState } from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import Container from './layout/Container'
import { Button } from '@chakra-ui/button'
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import Logo from './Logo'
import { useRouter } from 'next/router'
import { LeicaLink } from './ALink'
import { TFunction, useTranslation } from 'next-i18next'
import LocaleButton from './LocaleButton'

const menuItems = (t: TFunction) => {
  return [
    {
      name: t('menu:blog'),
      path: '/blog',
      isExternal: false,
    },
  ]
}

const NavigationLinks = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const formLink =
    'https://app.leiacatering.ee/create_company_page'

  return (
    <Flex as="div" direction={{ base: 'column', md: 'row' }} align="center">
      {menuItems(t).map((item, idx) => {
        return (
          <LeicaLink
            key={idx}
            href={item.path}
            py={{ base: 2, md: 0, lg: 0 }}
            isExternal={item.isExternal}
          >
            <Button variant="link" mx={{ base: 'none', md: 5 }} textColor="white">
              {item.name}
            </Button>
          </LeicaLink>
        )
      })}
      <LocaleButton />
    </Flex>
  )
}

const Navbar = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  return (
    <Box as="nav" p={2} bg="blue1">
      <Container>
        <Flex justify="space-between" align="center" wrap="wrap">
          <Box w={{base: 2/3, md: 1/3}}>
            <Logo />
          </Box>
          <Box display={{ base: 'block', md: 'none' }} onClick={toggleMenu} color="white">
            {showMenu ? <CloseIcon /> : <HamburgerIcon />}
          </Box>
          <Box
            display={{ base: showMenu ? 'block' : 'none', md: 'block' }}
            flexBasis={{ base: '100%', md: 'auto' }}
          >
            <Flex
              align={{ base: 'none', md: 'center' }}
              py={{ base: 5, md: 0 }}
              justify={{ base: 'flex-start', md: 'flex-end' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <NavigationLinks />
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Navbar
